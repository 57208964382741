import React from 'react';

const DashboardOverview = () => {
  // Replace the dummy data with your actual data for dashboard overview
  const userProgress = 75;
  const testsTaken = 10;
  const averageScore = 85;
  const badgesEarned = 5;

  return (
    <div>
      {/* <h2 className="text-2xl font-bold mb-4">Dashboard Overview</h2> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-xl font-bold mb-2">User Progress</h3>
          <div className="flex items-center">
            <div className="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold text-2xl mr-4">
              {userProgress}%
            </div>
            <div>
              <p>Keep up the good work!</p>
              <p>Your progress is on track.</p>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-xl font-bold mb-2">Tests Taken</h3>
          <p className="text-3xl font-bold mb-4">{testsTaken}</p>
          <p>You have taken {testsTaken} tests so far.</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-xl font-bold mb-2">Average Score</h3>
          <p className="text-3xl font-bold mb-4">{averageScore}%</p>
          <p>Your average score across all tests is {averageScore}%.</p>
        </div>
        <div className="bg-white rounded-lg shadow-md p-4">
          <h3 className="text-xl font-bold mb-2">Badges Earned</h3>
          <p className="text-3xl font-bold mb-4">{badgesEarned}</p>
          <p>You have earned {badgesEarned} badges so far.</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;
