import React from 'react';

const Discussions = () => {
  const discussions = [
    {
      id: 1,
      message: 'Best Practices for React State Management',
      author: 'Jane Doe',
      time: 'July 15, 2023 10:30 AM',
    },
    {
      id: 2,
      message: 'UI Design Trends for 2023',
      author: 'John Smith',
      time: 'July 12, 2023 3:45 PM',
    },
    // Add more discussions as needed
  ];

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-4">
      {/* Section Title */}
      <h2 className="text-2xl font-bold mb-4">Discussions</h2>

      {/* Chat Messages */}
      <div className="space-y-4">
        {discussions.map((discussion) => (
          <div key={discussion.id} className="flex space-x-2">
            <img
              src="https://via.placeholder.com/40"
              alt={discussion.author}
              className="w-8 h-8 rounded-full"
            />
            <div className="flex flex-col">
              <span className="font-semibold">{discussion.author}</span>
              <div className="bg-gray-200 p-2 rounded-lg">
                <p className="text-sm">{discussion.message}</p>
              </div>
              <span className="text-xs text-gray-500">{discussion.time}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Discussions;
