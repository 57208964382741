import React, { useState } from 'react';
import DashboardSidebar from '../minors/DashboardSidebar';
import DashboardOverview from '../minors/DashboardOverview';
//import DashboardML from '../minors/DashboardML';
import Header from '../Header';
import Footer from '../Footer';
//import ExamInstructions from '../minors/ExamInstructions';
import NewProjectForm from './NewProjectForm';
import ProjectCRUD from './ProjectCRUD';
import Certificate from './Certificate';

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState('overview');

  const handleSidebarClick = (componentName) => {
    setActiveComponent(componentName);
  };

  return (
    <>
      <Header />
      <div className="p-4 mx-auto max-w-7xl w-full">
        {/* <h2 className="text-3xl font-bold mb-6">Dashboard</h2> */}
        <div className="flex flex-col md:flex-row">
          <div className="md:w-[20rem] md:border-r-2 md:border-gray-400 ">
            <DashboardSidebar onSidebarClick={handleSidebarClick} />
          </div>
          <div className="md:w-full p-4">
            {activeComponent === 'overview' && <DashboardOverview />}
            {activeComponent === 'testHistory' && <ProjectCRUD />}
            {activeComponent === 'revisionMaterials' && <Certificate />}
            {activeComponent === 'profile' && <NewProjectForm />}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
