import React, { useState } from 'react';
import ProjectDetails from './ProjectDetails';
import TeamDetails from './TeamDetails';
import TaskManagement from './TaskManagement';
import Discussions from './Discussions';
import Milestones from './Milestones';
import Header from '../../Header';
import Footer from '../../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faTimes, faArrowRight , faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ProjectManagement = () => {
  const [selectedMenu, setSelectedMenu] = useState('project-details');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    setIsSidebarOpen(false); // Close sidebar when a menu option is clicked (on mobile)
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const toggleSidebarMinimization = () => {
    setIsSidebarMinimized((prev) => !prev);
  };

  return (
      <>
      <Header />
    <div className=" mx-auto px-4 p-6 md:pl-4 md:pr-0 md:pt-4 md:pb-0">
        <div className='flex flex-col md:flex-row ' >
      {/* Mobile Hamburger Menu */}
      <div className="md:hidden bg-blue-600 text-white w-full p-4 ">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">Project Management</h2>
          <button
            className="text-white focus:outline-none"
            onClick={toggleSidebar}
          >
           <FontAwesomeIcon
  icon={faEllipsisV}
  className={`h-6 w-6 ${isSidebarOpen ? 'hidden' : 'block'}`}
/>

<FontAwesomeIcon
  icon={isSidebarOpen ? faTimes : faEllipsisV}
  className={`h-6 w-6 ${isSidebarOpen ? 'block' : 'hidden'}`}
/>
          </button>
        </div>
        <div className={`${isSidebarOpen ? 'block' : 'hidden'} mt-4`}>
          <ul>
            <li
              className={`cursor-pointer py-2  pl-2 ${
                selectedMenu === 'project-details' ? 'bg-blue-500 pl-2 rounded' : ''
              }`}
              onClick={() => handleMenuClick('project-details')}
            >
              Project Details
            </li>
            <li
              className={`cursor-pointer py-2 pl-2  ${
                selectedMenu === 'team-details' ? 'bg-blue-500 pl-2 rounded' : ''
              }`}
              onClick={() => handleMenuClick('team-details')}
            >
              Team Details
            </li>
            <li
              className={`cursor-pointer py-2 pl-2 ${
                selectedMenu === 'task-management' ? 'bg-blue-500 pl-2 rounded' : ''
              }`}
              onClick={() => handleMenuClick('task-management')}
            >
              Task Management
            </li>
            <li
              className={`cursor-pointer py-2  pl-2 ${
                selectedMenu === 'discussions' ? 'bg-blue-500 pl-2 rounded' : ''
              }`}
              onClick={() => handleMenuClick('discussions')}
            >
              Discussions
            </li>
            <li
              className={`cursor-pointer py-2  pl-2 ${
                selectedMenu === 'milestones' ? 'bg-blue-500 pl-2 rounded' : ''
              }`}
              onClick={() => handleMenuClick('milestones')}
            >
              Milestones
            </li>
            {/* Add more menu options as needed */}
          </ul>
        </div>
      </div>

      {/* Sidebar for Desktop */}
      {/* Sidebar for Desktop */}
      <div
            className={`hidden md:flex bg-white text-gray-800 border-r-2 border-white ${
              isSidebarMinimized ? 'w-20' : 'w-[20rem]'
            } p-4 transition-all duration-300`}
          >
            <div className="flex flex-col space-y-2">
              <div className="flex items-center justify-between">
                <h2 className={`text-xl font-bold p-2 ${isSidebarMinimized ? 'hidden' : 'block'}`}>Project ABC</h2>
                <button
                  className="text-gray-800 focus:outline-none"
                  onClick={toggleSidebarMinimization}
                >
                  <FontAwesomeIcon
                    icon={isSidebarMinimized ? faArrowRight : faArrowLeft}
                    className="h-6 w-6 rounded-full bg-blue-100 p-2 m-2"
                  />
                </button>
              </div>
              <ul className={`${isSidebarMinimized ? 'hidden' : 'block'}`}>
            {/* Menu options */}
            <li
              className={`cursor-pointer py-2 p-2 pl-4 ${
                selectedMenu === 'project-details' ? 'bg-blue-500  rounded-full pl-4 text-white' : ''
              }`}
              onClick={() => handleMenuClick('project-details')}
            >
              Project Details
            </li>
            <li
              className={`cursor-pointer py-2 p-2 pl-4  ${
                selectedMenu === 'team-details' ? 'bg-blue-500  rounded-full pl-4 text-white' : ''
              }`}
              onClick={() => handleMenuClick('team-details')}
            >
              Team Details
            </li>
            <li
              className={`cursor-pointer py-2 p-2 pl-4 ${
                selectedMenu === 'task-management' ? 'bg-blue-500 rounded-full pl-4 text-white' : ''
              }`}
              onClick={() => handleMenuClick('task-management')}
            >
              Task Management
            </li>
            <li
              className={`cursor-pointer py-2 p-2 pl-4 ${
                selectedMenu === 'discussions' ? 'bg-blue-500  rounded-full pl-4 text-white' : ''
              }`}
              onClick={() => handleMenuClick('discussions')}
            >
              Discussions
            </li>
            <li
              className={`cursor-pointer py-2 p-2 pl-4 ${
                selectedMenu === 'milestones' ? 'bg-blue-500  rounded-full pl-4 text-white' : ''
              }`}
              onClick={() => handleMenuClick('milestones')}
            >
              Milestones
            </li>
            {/* Add more menu options as needed */}
          </ul>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="w-full p-4 bg-gray-100 md:rounded-tl-lg">
        {/* Content for selected menu option */}
        {selectedMenu === 'project-details' && (
          <div>
            <h3 className="text-xl font-bold mb-4">Project Details</h3>
            {/* Your Project Details Component */}
            <ProjectDetails />
          </div>
        )}

        {selectedMenu === 'team-details' && (
          <div>
            <h3 className="text-xl font-bold mb-4">Team Details</h3>
            {/* Your Team Details Component */}
            <TeamDetails />
          </div>
        )}

        {selectedMenu === 'task-management' && (
          <div>
            <h3 className="text-xl font-bold mb-4">Task Management</h3>
            {/* Your Task Management Component */}
            <TaskManagement />
          </div>
        )}

        {selectedMenu === 'discussions' && (
          <div>
            <h3 className="text-xl font-bold mb-4">Discussions</h3>
            {/* Your Discussions Component */}
            <Discussions />
          </div>
        )}

        {selectedMenu === 'milestones' && (
          <div>
            <h3 className="text-xl font-bold mb-4">Milestones</h3>
            {/* Your Milestones Component */}
            <Milestones />
          </div>
        )}
        {/* Add more content for other menu options as needed */}
      </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default ProjectManagement;
