import React, { useState } from 'react';

const ProjectForm = () => {
  const [projectData, setProjectData] = useState({
    projectName: 'Online Learning Platform',
    projectDescription: 'Develop an online platform for e-learning',
    projectManager: 'John Doe',
    assignedStudents: ['student1', 'student2', 'student3'],
    projectStatus: 'In Progress',
    projectTasks: [
      {
        taskName: 'Design Database Schema',
        taskDescription: 'Create a database schema for storing user data, courses, and progress.',
        taskStatus: 'Completed',
        taskAssignee: 'student1',
        taskDeadline: '2024-04-15',
      },
      {
        taskName: 'Implement User Authentication',
        taskDescription: 'Implement user authentication and authorization functionality.',
        taskStatus: 'In Progress',
        taskAssignee: 'student2',
        taskDeadline: '2024-04-20',
      },
      {
        taskName: 'Develop Course Management System',
        taskDescription: 'Build a system for managing courses, including creation, editing, and deletion.',
        taskStatus: 'To Do',
        taskAssignee: '',
        taskDeadline: '2024-05-01',
      },
    ],
    projectResources: ['AWS credits', 'Online tutorials'],
    projectDocuments: ['https://example.com/project-docs'],
    projectBudget: 5000,
    projectExpenses: 2500,
    communicationChannels: ['Slack', 'Email'],
    projectFeedback: 'Great progress so far!',
    projectRisks: 'Limited availability of team members',
    projectAchievements: ['Database design completed'],
    projectEvaluation: 'Project progress will be evaluated based on functionality and user feedback.',
    supportRequests: ['supportRequest1', 'supportRequest2'],
    projectCompletionCertificate: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
  };

  const handleArrayInputChange = (e, index, field) => {
    const { value } = e.target;
    const updatedArray = [...projectData[field]];
    updatedArray[index] = value;
    setProjectData({ ...projectData, [field]: updatedArray });
  };

  const handleTaskChange = (index, field, value) => {
    const updatedTasks = [...projectData.projectTasks];
    updatedTasks[index][field] = value;
    setProjectData({ ...projectData, projectTasks: updatedTasks });
  };

  const addTask = () => {
    const newTask = {
      taskName: '',
      taskDescription: '',
      taskStatus: '',
      taskAssignee: '',
      taskDeadline: '',
    };
    setProjectData({ ...projectData, projectTasks: [...projectData.projectTasks, newTask] });
  };

  const removeTask = (index) => {
    const updatedTasks = [...projectData.projectTasks];
    updatedTasks.splice(index, 1);
    setProjectData({ ...projectData, projectTasks: updatedTasks });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Post data to demo API
    console.log(projectData);
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-4xl mx-auto p-8 bg-white shadow-md rounded-lg">
      <div className="mb-4">
        <label htmlFor="projectName" className="block font-bold mb-2">
          Project Name
        </label>
        <input
          type="text"
          id="projectName"
          name="projectName"
          value={projectData.projectName}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="projectDescription" className="block font-bold mb-2">
          Project Description
        </label>
        <textarea
          id="projectDescription"
          name="projectDescription"
          value={projectData.projectDescription}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="projectManager" className="block font-bold mb-2">
          Project Manager
        </label>
        <input
          type="text"
          id="projectManager"
          name="projectManager"
          value={projectData.projectManager}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="assignedStudents" className="block font-bold mb-2">
          Assigned Students
        </label>
        {projectData.assignedStudents.map((student, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={student}
              onChange={(e) => handleArrayInputChange(e, index, 'assignedStudents')}
              className="border border-gray-400 p-2 rounded-md mr-2"
            />
            <button
              type="button"
              onClick={() => {
                const updatedStudents = [...projectData.assignedStudents];
                updatedStudents.splice(index, 1);
                setProjectData({ ...projectData, assignedStudents: updatedStudents });
              }}
              className="bg-red-500 text-white py-1 px-2 rounded-md"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setProjectData({ ...projectData, assignedStudents: [...projectData.assignedStudents, ''] })}
          className="bg-green-500 text-white py-1 px-2 rounded-md"
        >
          Add Student
        </button>
      </div>

      <div className="mb-4">
        <label htmlFor="projectStatus" className="block font-bold mb-2">
          Project Status
        </label>
        <select
          id="projectStatus"
          name="projectStatus"
          value={projectData.projectStatus}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        >
          <option value="In Progress">In Progress</option>
          <option value="Completed">Completed</option>
          <option value="On Hold">On Hold</option>
        </select>
      </div>

      {/* Project Tasks */}
      <div className="mb-4">
        <label htmlFor="projectTasks" className="block font-bold mb-2">
          Project Tasks
        </label>
        {projectData.projectTasks.map((task, index) => (
          <div key={index} className="mb-4 border border-gray-400 p-4 rounded-md">
            <div className="mb-2">
              <label htmlFor={`taskName-${index}`} className="block font-bold">
                Task Name
              </label>
              <input
                type="text"
                id={`taskName-${index}`}
                name="taskName"
                value={task.taskName}
                onChange={(e) => handleTaskChange(index, 'taskName', e.target.value)}
                className="border border-gray-400 p-2 w-full rounded-md"
              />
            </div>

            <div className="mb-2">
              <label htmlFor={`taskDescription-${index}`} className="block font-bold">
                Task Description
              </label>
              <textarea
                id={`taskDescription-${index}`}
                name="taskDescription"
                value={task.taskDescription}
                onChange={(e) => handleTaskChange(index, 'taskDescription', e.target.value)}
                className="border border-gray-400 p-2 w-full rounded-md"
              />
            </div>

            <div className="mb-2">
              <label htmlFor={`taskStatus-${index}`} className="block font-bold">
                Task Status
              </label>
              <select
                id={`taskStatus-${index}`}
                name="taskStatus"
                value={task.taskStatus}
                onChange={(e) => handleTaskChange(index, 'taskStatus', e.target.value)}
                className="border border-gray-400 p-2 w-full rounded-md"
              >
                <option value="Completed">Completed</option>
                <option value="In Progress">In Progress</option>
                <option value="To Do">To Do</option>
              </select>
            </div>

            <div className="mb-2">
              <label htmlFor={`taskAssignee-${index}`} className="block font-bold">
                Task Assignee
              </label>
              <input
                type="text"
                id={`taskAssignee-${index}`}
                name="taskAssignee"
                value={task.taskAssignee}
                onChange={(e) => handleTaskChange(index, 'taskAssignee', e.target.value)}
                className="border border-gray-400 p-2 w-full rounded-md"
              />
            </div>

            <div className="mb-2">
              <label htmlFor={`taskDeadline-${index}`} className="block font-bold">
                Task Deadline
              </label>
              <input
                type="date"
                id={`taskDeadline-${index}`}
                name="taskDeadline"
                value={task.taskDeadline}
                onChange={(e) => handleTaskChange(index, 'taskDeadline', e.target.value)}
                className="border border-gray-400 p-2 w-full rounded-md"
              />
            </div>

            <button
              type="button"
              onClick={() => removeTask(index)}
              className="bg-red-500 text-white py-1 px-2 rounded-md"
            >
              Remove Task
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={addTask}
          className="bg-green-500 text-white py-1 px-2 rounded-md"
        >
          Add Task
        </button>
      </div>

      <div className="mb-4">
        <label htmlFor="projectResources" className="block font-bold mb-2">
          Project Resources
        </label>
        {projectData.projectResources.map((resource, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={resource}
              onChange={(e) => handleArrayInputChange(e, index, 'projectResources')}
              className="border border-gray-400 p-2 rounded-md mr-2"
            />
            <button
              type="button"
              onClick={() => {
                const updatedResources = [...projectData.projectResources];
                updatedResources.splice(index, 1);
                setProjectData({ ...projectData, projectResources: updatedResources });
              }}
              className="bg-red-500 text-white py-1 px-2 rounded-md"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setProjectData({ ...projectData, projectResources: [...projectData.projectResources, ''] })}
          className="bg-green-500 text-white py-1 px-2 rounded-md"
        >
          Add Resource
        </button>
      </div>

      <div className="mb-4">
        <label htmlFor="projectDocuments" className="block font-bold mb-2">
          Project Documents
        </label>
        {projectData.projectDocuments.map((document, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={document}
              onChange={(e) => handleArrayInputChange(e, index, 'projectDocuments')}
              className="border border-gray-400 p-2 rounded-md mr-2"
            />
            <button
              type="button"
              onClick={() => {
                const updatedDocuments = [...projectData.projectDocuments];
                updatedDocuments.splice(index, 1);
                setProjectData({ ...projectData, projectDocuments: updatedDocuments });
              }}
              className="bg-red-500 text-white py-1 px-2 rounded-md"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setProjectData({ ...projectData, projectDocuments: [...projectData.projectDocuments, ''] })}
          className="bg-green-500 text-white py-1 px-2 rounded-md"
        >
          Add Document
        </button>
      </div>

      <div className="mb-4">
        <label htmlFor="projectBudget" className="block font-bold mb-2">
          Project Budget
        </label>
        <input
          type="number"
          id="projectBudget"
          name="projectBudget"
          value={projectData.projectBudget}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="projectExpenses" className="block font-bold mb-2">
          Project Expenses
        </label>
        <input
          type="number"
          id="projectExpenses"
          name="projectExpenses"
          value={projectData.projectExpenses}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="communicationChannels" className="block font-bold mb-2">
          Communication Channels
        </label>
        {projectData.communicationChannels.map((channel, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={channel}
              onChange={(e) => handleArrayInputChange(e, index, 'communicationChannels')}
              className="border border-gray-400 p-2 rounded-md mr-2"
            />
            <button
              type="button"
              onClick={() => {
                const updatedChannels = [...projectData.communicationChannels];
                updatedChannels.splice(index, 1);
                setProjectData({ ...projectData, communicationChannels: updatedChannels });
              }}
              className="bg-red-500 text-white py-1 px-2 rounded-md"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setProjectData({ ...projectData, communicationChannels: [...projectData.communicationChannels, ''] })}
          className="bg-green-500 text-white py-1 px-2 rounded-md"
        >
          Add Channel
        </button>
      </div>

      <div className="mb-4">
        <label htmlFor="projectFeedback" className="block font-bold mb-2">
          Project Feedback
        </label>
        <textarea
          id="projectFeedback"
          name="projectFeedback"
          value={projectData.projectFeedback}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="projectRisks" className="block font-bold mb-2">
          Project Risks
        </label>
        <input
          type="text"
          id="projectRisks"
          name="projectRisks"
          value={projectData.projectRisks}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="projectAchievements" className="block font-bold mb-2">
          Project Achievements
        </label>
        {projectData.projectAchievements.map((achievement, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={achievement}
              onChange={(e) => handleArrayInputChange(e, index, 'projectAchievements')}
              className="border border-gray-400 p-2 rounded-md mr-2"
            />
            <button
              type="button"
              onClick={() => {
                const updatedAchievements = [...projectData.projectAchievements];
                updatedAchievements.splice(index, 1);
                setProjectData({ ...projectData, projectAchievements: updatedAchievements });
              }}
              className="bg-red-500 text-white py-1 px-2 rounded-md"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setProjectData({ ...projectData, projectAchievements: [...projectData.projectAchievements, ''] })}
          className="bg-green-500 text-white py-1 px-2 rounded-md"
        >
          Add Achievement
        </button>
      </div>

      <div className="mb-4">
        <label htmlFor="projectEvaluation" className="block font-bold mb-2">
          Project Evaluation
        </label>
        <textarea
          id="projectEvaluation"
          name="projectEvaluation"
          value={projectData.projectEvaluation}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="supportRequests" className="block font-bold mb-2">
          Support Requests
        </label>
        {projectData.supportRequests.map((request, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={request}
              onChange={(e) => handleArrayInputChange(e, index, 'supportRequests')}
              className="border border-gray-400 p-2 rounded-md mr-2"
            />
            <button
              type="button"
              onClick={() => {
                const updatedRequests = [...projectData.supportRequests];
                updatedRequests.splice(index, 1);
                setProjectData({ ...projectData, supportRequests: updatedRequests });
              }}
              className="bg-red-500 text-white py-1 px-2 rounded-md"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setProjectData({ ...projectData, supportRequests: [...projectData.supportRequests, ''] })}
          className="bg-green-500 text-white py-1 px-2 rounded-md"
        >
          Add Request
        </button>
      </div>

      <div className="mb-4">
        <label htmlFor="projectCompletionCertificate" className="block font-bold mb-2">
          Project Completion Certificate
        </label>
        <input
          type="text"
          id="projectCompletionCertificate"
          name="projectCompletionCertificate"
          value={projectData.projectCompletionCertificate}
          onChange={handleInputChange}
          className="border border-gray-400 p-2 w-full rounded-md"
        />
      </div>

      <button
        type="submit"
        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
      >
        Submit
      </button>
    </form>
  );
};

export default ProjectForm;