import React from 'react';

const Certificate = () => {
  // Demo data (to be replaced with props)
  const studentName = 'Shubham Sharma';
  const courseName = 'Web Development Bootcamp';
  const grade = 'A+';
  const desc = 'is hereby rewared the certificate for succeess completion of the project ';
  const date = new Date().toLocaleDateString();
  //const projectId = '123456';
  const director = 'Jane Smith';
  const manager = 'Michael Johnson';
  const certificateId = 'RC-ABC123';

  return (
    <div className="bg-white p-4 rounded-lg shadow-md m-2 border-4 border-blue-600 ">
      <div className="border-8 border-blue-600 rounded-lg p-8 relative">
        <div className="bg-white rounded-lg p-8">
          <div className="text-center mb-4">
            <h1 className="text-4xl font-bold text-blue-600">Remote Campus</h1>
            <p className="text-lg font-semibold text-gray-600 mt-2">Certificate of Completion</p>
          </div>
          <div className="text-center mb-8 mt-8">
            <h2 className="text-6xl  text-gray-800">{studentName}</h2>
          </div>
          <div className="mb-8">
            <p className="text-lg font-semibold text-gray-800 text-center">Course:</p>
            <p className="text-2xl font-bold text-blue-600 text-center">{courseName}</p>
            <p className="text-xl font text-gray-400 text-center mx-auto max-w-xl">{desc}</p>
          </div>
          <div className="mb-8">
            <p className="text-lg font-semibold text-gray-800 text-center">Grade:</p>
            <p className="text-4xl font-bold text-green-600 text-center">{grade}</p>
          </div>
        
          <div className="flex justify-between mt-8">
            <div>
              <p className="text-gray-600">Director</p>
              <p className="text-lg font-bold text-gray-800">{director}</p>
              <div className="h-12 w-24 border-2 border-gray-800 mt-2"></div>
            </div>
         
            <div>
              <p className="text-gray-600">Manager</p>
              <p className="text-lg font-bold text-gray-800">{manager}</p>
              <div className="h-12 w-24 border-2 border-gray-800 mt-2"> s</div>
            </div>
          </div>
        </div>
        <p className="text-sm text-gray-600 absolute bottom-4 right-4">Certificate ID: {certificateId}</p>
        <p className="text-sm text-gray-600 absolute bottom-4 left-4">Date of Completion: {date}</p>
         
      </div>
    </div>
  );
};

export default Certificate;