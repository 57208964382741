import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="bg-blue-900 h-screen flex flex-col justify-center items-center text-white">
      <h1 className="text-4xl md:text-5xl font-semibold mb-4">404 - Page Not Found</h1>
      <p className="text-lg md:text-xl text-center mb-8">
        Oops! The page you are looking for does not exist. It seems you have taken a
        wrong turn.
      </p>
      <a
        href="/"
        className="bg-yellow-400 hover:bg-yellow-500 text-blue-900 px-6 py-2 rounded-md text-lg transition-transform transform hover:scale-105 focus:outline-none"
      >
        Go to Home
      </a>
      <div className="mt-8 text-sm">
        Or, explore some of our popular pages:
      </div>
      <ul className="mt-4 text-lg text-center space-y-2">
        <li>
          <a
            href="/about"
            className="text-yellow-400 hover:text-yellow-300 transition-colors"
          >
            About Us
          </a>
        </li>
        <li>
          <a
            href="/services"
            className="text-yellow-400 hover:text-yellow-300 transition-colors"
          >
            Services
          </a>
        </li>
        <li>
          <a
            href="/contact"
            className="text-yellow-400 hover:text-yellow-300 transition-colors"
          >
            Contact Us
          </a>
        </li>
      </ul>
    </div>
  );
};

export default NotFoundPage;
