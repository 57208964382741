/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const DashboardSidebar = ({ onSidebarClick }) => {
  const [activeComponent, setActiveComponent] = useState('overview');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleItemClick = (componentName) => {
    setActiveComponent(componentName);
    onSidebarClick(componentName);
    setIsDropdownOpen(false); // Close the dropdown when an item is clicked
  };

  const sidebarItems = [
    { name: 'Dashboard', key: 'overview' },
    { name: 'Profile', key: 'profile' },
    { name: 'Test History', key: 'testHistory' },
    { name: 'Revision Materials', key: 'revisionMaterials' },
    { name: 'Community Forum', key: 'communityForum' },
    { name: 'Ebooks', key: 'ebooks' },
    { name: 'Contact Us', key: 'contactUs' },
    { name: 'Logout', key: 'logout' },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <div className="bg-white h-full rounded-md px-4 py-8">
      <div className="md:hidden">
        <button
          className="flex items-center justify-between w-full py-2 px-4 rounded  bg-blue-500 text-white hover:bg-blue-600 focus:outline-none"
          onClick={toggleDropdown}
        >
          <span>Menu</span>
          <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} />
        </button>
        {isDropdownOpen && (
          <ul className="mb-2 space-y-2 bg-gray-100">
            {sidebarItems.map((item) => (
              <li
                key={item.key}
                onClick={() => handleItemClick(item.key)}
                className={`cursor-pointer py-2 px-4  ${
                  activeComponent === item.key ? 'bg-white text-blue-800 border-b-2 border-blue-800 font-semibold' : 'text-gray-800 hover:bg-gray-100'
                }`}
              >
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="hidden md:block">
        <ul className="space-y-2">
          {sidebarItems.map((item) => (
            <li
              key={item.key}
              onClick={() => handleItemClick(item.key)}
              className={`cursor-pointer py-2 px-4 rounded-lg ${
                activeComponent === item.key ? 'bg-blue-500 text-white' : 'text-gray-800 hover:bg-gray-100'
              }`}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardSidebar;