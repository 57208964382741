import React, { useState } from 'react';

const ProjectCRUD = () => {
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState({
    projectName: '',
    projectDescription: '',
    projectStatus: '',
    projectNotes: '',
    assignedStudents: [],
    projectTasks: [],
  });
  const [editingProject, setEditingProject] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [newStudent, setNewStudent] = useState('');
  const [newTask, setNewTask] = useState({ taskName: '', taskDescription: '', taskStatus: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProject({ ...newProject, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProjects([...projects, newProject]);
    setNewProject({
      projectName: '',
      projectDescription: '',
      projectStatus: '',
      projectNotes: '',
      assignedStudents: [],
      projectTasks: [],
    });
    setNewStudent('');
    setNewTask({ taskName: '', taskDescription: '', taskStatus: '' });
  };

  const handleDelete = (index) => {
    const updatedProjects = [...projects];
    updatedProjects.splice(index, 1);
    setProjects(updatedProjects);
  };

  const handleEdit = (project, index) => {
    setEditingProject({ ...project, index });
    setShowModal(true);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const updatedProjects = [...projects];
    updatedProjects[editingProject.index] = editingProject;
    setProjects(updatedProjects);
    setEditingProject(null);
    setShowModal(false);
  };

  const handleCancel = () => {
    setEditingProject(null);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setEditingProject(null);
    setShowModal(false);
  };

  const handleViewProject = (project) => {
    setModalContent(
      <div>
        <h3 className="text-xl font-bold mb-4">{project.projectName}</h3>
        <p className="mb-2">
          <span className="font-bold">Description:</span>{' '}
          {project.projectDescription}
        </p>
        <p className="mb-2">
          <span className="font-bold">Status:</span> {project.projectStatus}
        </p>
        <p className="mb-2">
          <span className="font-bold">Notes:</span> {project.projectNotes}
        </p>
        <p className="mb-2">
          <span className="font-bold">Assigned Students:</span>{' '}
          {project.assignedStudents.join(', ')}
        </p>
        <div className="mb-4">
          <h4 className="text-lg font-bold mb-2">Project Tasks:</h4>
          {project.projectTasks.map((task, index) => (
            <div key={index} className="mb-2">
              <p>
                <span className="font-bold">Task Name:</span> {task.taskName}
              </p>
              <p>
                <span className="font-bold">Task Description:</span> {task.taskDescription}
              </p>
              <p>
                <span className="font-bold">Task Status:</span> {task.taskStatus}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
    setShowModal(true);
  };


  const handleAddStudent = () => {
    if (newStudent.trim() !== '') {
      setNewProject({
        ...newProject,
        assignedStudents: [...newProject.assignedStudents, newStudent.trim()],
      });
      setNewStudent('');
    }
  };

  const handleRemoveStudent = (index) => {
    const updatedStudents = [...newProject.assignedStudents];
    updatedStudents.splice(index, 1);
    setNewProject({ ...newProject, assignedStudents: updatedStudents });
  };

  const handleAddTask = () => {
    if (newTask.taskName.trim() !== '') {
      setNewProject({
        ...newProject,
        projectTasks: [...newProject.projectTasks, newTask],
      });
      setNewTask({ taskName: '', taskDescription: '', taskStatus: '' });
    }
  };

  const handleRemoveTask = (index) => {
    const updatedTasks = [...newProject.projectTasks];
    updatedTasks.splice(index, 1);
    setNewProject({ ...newProject, projectTasks: updatedTasks });
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8 text-center">Project CRUD</h1>

      {/* Add Project Form */}
      <div className="bg-white rounded-lg shadow-md p-8 mb-8">
        <h2 className="text-xl font-bold mb-4">Add Project</h2>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div>
            <label htmlFor="projectName" className="block font-bold mb-2">
              Project Name
            </label>
            <input
              type="text"
              id="projectName"
              name="projectName"
              value={newProject.projectName}
              onChange={handleChange}
              className="border border-gray-400 p-2 w-full rounded-md"
            />
          </div>
          <div>
            <label htmlFor="projectDescription" className="block font-bold mb-2">
              Project Description
            </label>
            <textarea
              id="projectDescription"
              name="projectDescription"
              value={newProject.projectDescription}
              onChange={handleChange}
              className="border border-gray-400 p-2 w-full rounded-md resize-none"
              rows="3"
            ></textarea>
          </div>
          <div>
            <label htmlFor="projectStatus" className="block font-bold mb-2">
              Project Status
            </label>
            <select
              id="projectStatus"
              name="projectStatus"
              value={newProject.projectStatus}
              onChange={handleChange}
              className="border border-gray-400 p-2 w-full rounded-md"
            >
              <option value="">Select Status</option>
              <option value="In Progress">In Progress</option>
              <option value="Completed">Completed</option>
              <option value="On Hold">On Hold</option>
            </select>
          </div>
          <div className="md:col-span-2 lg:col-span-3">
            <label htmlFor="projectNotes" className="block font-bold mb-2">
              Project Notes
            </label>
            <textarea
              id="projectNotes"
              name="projectNotes"
              value={newProject.projectNotes}
              onChange={handleChange}
              className="border border-gray-400 p-2 w-full rounded-md resize-none"
              rows="4"
            ></textarea>
          </div>
          <div className="md:col-span-2 lg:col-span-3">
            <label htmlFor="assignedStudents" className="block font-bold mb-2">
              Assigned Students
            </label>
            <div className="flex items-center mb-2">
              <input
                type="text"
                id="assignedStudents"
                value={newStudent}
                onChange={(e) => setNewStudent(e.target.value)}
                className="border border-gray-400 p-2 rounded-md mr-2 flex-grow"
                placeholder="Enter student name"
              />
              <button
                type="button"
                onClick={handleAddStudent}
                className="bg-green-500 text-white py-1 px-2 rounded-md hover:bg-green-600"
              >
                Add
              </button>
            </div>
            <div>
              {newProject.assignedStudents.map((student, index) => (
                <div key={index} className="flex items-center mb-1">
                  <span className="flex-grow">{student}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveStudent(index)}
                    className="bg-red-500 text-white py-1 px-2 rounded-md hover:bg-red-600"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="md:col-span-3">
            <label htmlFor="projectTasks" className="block font-bold mb-2">
              Project Tasks
            </label>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-2">
              <div>
                <label htmlFor="taskName" className="block font-bold mb-1">
                  Task Name
                </label>
                <input
                  type="text"
                  id="taskName"
                  value={newTask.taskName}
                  onChange={(e) => setNewTask({ ...newTask, taskName: e.target.value })}
                  className="border border-gray-400 p-2 w-full rounded-md"
                  placeholder="Enter task name"
                />
              </div>
              <div>
                <label htmlFor="taskDescription" className="block font-bold mb-1">
                  Task Description
                </label>
                <input
                  type="text"
                  id="taskDescription"
                  value={newTask.taskDescription}
                  onChange={(e) => setNewTask({ ...newTask, taskDescription: e.target.value })}
                  className="border border-gray-400 p-2 w-full rounded-md"
                  placeholder="Enter task description"
                />
              </div>
              <div>
                <label htmlFor="taskStatus" className="block font-bold mb-1">
                  Task Status
                </label>
                <select
                  id="taskStatus"
                  value={newTask.taskStatus}
                  onChange={(e) => setNewTask({ ...newTask, taskStatus: e.target.value })}
                  className="border border-gray-400 p-2 w-full rounded-md"
                >
                  <option value="">Select Status</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                  <option value="On Hold">On Hold</option>
                </select>
              </div>
            </div>
            <div className="flex items-center">
              <button
                type="button"
                onClick={handleAddTask}
                className="bg-green-500 text-white py-1 px-2 rounded-md hover:bg-green-600 mr-2"
              >
                Add Task
              </button>
            </div>
            <div>
              {newProject.projectTasks.map((task, index) => (
                <div key={index} className="flex items-center mb-1">
                  <div className="flex-grow">
                    <p>
                      <span className="font-bold">Task Name:</span> {task.taskName}
                    </p>
                    <p>
                      <span className="font-bold">Task Description:</span> {task.taskDescription}
                    </p>
                    <p>
                      <span className="font-bold">Task Status:</span> {task.taskStatus}
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleRemoveTask(index)}
                    className="bg-red-500 text-white py-1 px-2 rounded-md hover:bg-red-600"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          </div>
        </form>
        <button
          type="submit"
          onClick={handleSubmit}
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mt-4"
        >
          Submit
        </button>
      </div>

      {/* Project Table */}
      <table className="w-full">
        <thead>
          <tr>
            <th className="py-2 px-4 bg-gray-200 border">Project Name</th>
            <th className="py-2 px-4 bg-gray-200 border">Project Description</th>
            <th className="py-2 px-4 bg-gray-200 border">Project Status</th>
            <th className="py-2 px-4 bg-gray-200 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, index) => (
            <tr key={index}>
              <td className="py-2 px-4 border">{project.projectName}</td>
              <td className="py-2 px-4 border">{project.projectDescription}</td>
              <td className="py-2 px-4 border">{project.projectStatus}</td>
              <td className="py-2 px-4 border">
                <button
                  onClick={() => handleViewProject(project)}
                  className="bg-green-500 text-white py-1 px-2 rounded-md hover:bg-green-600 mr-2"
                >
                  View
                </button>
                <button
                  onClick={() => handleEdit(project, index)}
                  className="bg-blue-500 text-white py-1 px-2 rounded-md hover:bg-blue-600 mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(index)}
                  className="bg-red-500 text-white py-1 px-2 rounded-md hover:bg-red-600"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* View Project Modal */}
      {showModal && (
        <>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              <div className="bg-white rounded-lg shadow-md relative max-w-4xl w-full">
                <button
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                  onClick={handleCloseModal}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <div className="p-8">
                  {modalContent}
                  {editingProject ? (
                    <form onSubmit={handleUpdate} className="mt-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div>
                          <label htmlFor="projectName" className="block font-bold mb-2">
                            Project Name
                          </label>
                          <input
                            type="text"
                            id="projectName"
                            name="projectName"
                            value={editingProject.projectName}
                            onChange={(e) =>
                              setEditingProject({ ...editingProject, projectName: e.target.value })
                            }
                            className="border border-gray-400 p-2 w-full rounded-md"
                          />
                        </div>
                        <div>
                          <label htmlFor="projectDescription" className="block font-bold mb-2">
                            Project Description
                          </label>
                          <textarea
                            id="projectDescription"
                            name="projectDescription"
                            value={editingProject.projectDescription}
                            onChange={(e) =>
                              setEditingProject({
                                ...editingProject,
                                projectDescription: e.target.value,
                              })
                            }
                            className="border border-gray-400 p-2 w-full rounded-md resize-none"
                            rows="3"
                          ></textarea>
                        </div>
                        <div>
                          <label htmlFor="projectStatus" className="block font-bold mb-2">
                            Project Status
                          </label>
                          <select
                            id="projectStatus"
                            name="projectStatus"
                            value={editingProject.projectStatus}
                            onChange={(e) =>
                              setEditingProject({
                                ...editingProject,
                                projectStatus: e.target.value,
                              })
                            }
                            className="border border-gray-400 p-2 w-full rounded-md"
                          >
                            <option value="">Select Status</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Completed">Completed</option>
                            <option value="On Hold">On Hold</option>
                          </select>
                        </div>
                        <div className="md:col-span-2 lg:col-span-3">
                          <label htmlFor="projectNotes" className="block font-bold mb-2">
                            Project Notes
                          </label>
                          <textarea
                            id="projectNotes"
                            name="projectNotes"
                            value={editingProject.projectNotes}
                            onChange={(e) =>
                              setEditingProject({
                                ...editingProject,
                                projectNotes: e.target.value,
                              })
                            }
                            className="border border-gray-400 p-2 w-full rounded-md resize-none"
                            rows="4"
                          ></textarea>
                        </div>
                      </div>
                      <div className="md:col-span-3 mt-4">
                        <label htmlFor="assignedStudents" className="block font-bold mb-2">
                          Assigned Students
                        </label>
                        <div className="flex items-center mb-2">
                          <input
                            type="text"
                            id="assignedStudents"
                            value={newStudent}
                            onChange={(e) => setNewStudent(e.target.value)}
                            className="border border-gray-400 p-2 rounded-md mr-2 flex-grow"
                            placeholder="Enter student name"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              if (newStudent.trim() !== '') {
                                setEditingProject({
                                  ...editingProject,
                                  assignedStudents: [...editingProject.assignedStudents, newStudent.trim()],
                                });
                                setNewStudent('');
                              }
                            }}
                            className="bg-green-500 text-white py-1 px-2 rounded-md hover:bg-green-600"
                          >
                            Add
                          </button>
                        </div>
                        <div>
                          {editingProject.assignedStudents.map((student, index) => (
                            <div key={index} className="flex items-center mb-1">
                              <span className="flex-grow">{student}</span>
                              <button
                                type="button"
                                onClick={() => {
                                  const updatedStudents = [...editingProject.assignedStudents];
                                  updatedStudents.splice(index, 1);
                                  setEditingProject({
                                    ...editingProject,
                                    assignedStudents: updatedStudents,
                                  });
                                }}
                                className="bg-red-500 text-white py-1 px-2 rounded-md hover:bg-red-600"
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="md:col-span-3 mt-4">
                        <label htmlFor="projectTasks" className="block font-bold mb-2">
                          Project Tasks
                        </label>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-2">
                          <div>
                            <label htmlFor="taskName" className="block font-bold mb-1">
                              Task Name
                            </label>
                            <input
                              type="text"
                              id="taskName"
                              value={newTask.taskName}
                              onChange={(e) =>
                                setNewTask({ ...newTask, taskName: e.target.value })
                              }
                              className="border border-gray-400 p-2 w-full rounded-md"
                              placeholder="Enter task name"
                            />
                          </div>
                          <div>
                            <label htmlFor="taskDescription" className="block font-bold mb-1">
                              Task Description
                            </label>
                            <input
                              type="text"
                              id="taskDescription"
                              value={newTask.taskDescription}
                              onChange={(e) =>
                                setNewTask({ ...newTask, taskDescription: e.target.value })
                              }
                              className="border border-gray-400 p-2 w-full rounded-md"
                              placeholder="Enter task description"
                            />
                          </div>
                          <div>
                            <label htmlFor="taskStatus" className="block font-bold mb-1">
                              Task Status
                            </label>
                            <select
                              id="taskStatus"
                              value={newTask.taskStatus}
                              onChange={(e) =>
                                setNewTask({ ...newTask, taskStatus: e.target.value })
                              }
                              className="border border-gray-400 p-2 w-full rounded-md"
                            >
                              <option value="">Select Status</option>
                              <option value="In Progress">In Progress</option>
                              <option value="Completed">Completed</option>
                              <option value="On Hold">On Hold</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => {
                              if (newTask.taskName.trim() !== '') {
                                setEditingProject({
                                  ...editingProject,
                                  projectTasks: [...editingProject.projectTasks, newTask],
                                });
                                setNewTask({ taskName: '', taskDescription: '', taskStatus: '' });
                              }
                            }}
                            className="bg-green-500 text-white py-1 px-2 rounded-md hover:bg-green-600 mr-2"
                          >
                            Add Task
                          </button>
                        </div>
                        <div>
                          {editingProject.projectTasks.map((task, index) => (
                            <div key={index} className="flex items-center mb-1">
                              <div className="flex-grow">
                                <p>
                                  <span className="font-bold">Task Name:</span> {task.taskName}
                                </p>
                                <p>
                                  <span className="font-bold">Task Description:</span>{' '}
                                  {task.taskDescription}
                                </p>
                                <p>
                                  <span className="font-bold">Task Status:</span> {task.taskStatus}
                                </p>
                              </div>
                              <button
                                type="button"
                                onClick={() => {
                                  const updatedTasks = [...editingProject.projectTasks];
                                  updatedTasks.splice(index, 1);
                                  setEditingProject({
                                    ...editingProject,
                                    projectTasks: updatedTasks,
                                  });
                                }}
                                className="bg-red-500 text-white py-1 px-2 rounded-md hover:bg-red-600"
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="mt-4 flex justify-end">
                        <button
                          type="button"
                          onClick={handleCancel}
                          className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        </>
      )}
    </div>
  );
};

export default ProjectCRUD;