import React from 'react';

const TaskManagement = () => {
  const tasks = [
    {
      id: 1,
      title: 'Implement User Authentication',
      description:
        'Develop the login and registration functionalities for the application.',
      status: 'In Progress',
    },
    {
      id: 2,
      title: 'Create Dashboard UI',
      description: 'Design and code the user dashboard interface.',
      status: 'Completed',
    },
    // Add more tasks as needed
  ];

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-4">
      {/* Section Title */}
      <h2 className="text-2xl font-bold mb-4">Task Management</h2>

      {/* Tasks */}
      <div className="space-y-4">
        {tasks.map((task) => (
          <div key={task.id} className="border border-gray-300 rounded-lg p-4">
            <h3 className="text-lg font-semibold">{task.title}</h3>
            <p className="text-gray-600">{task.description}</p>
            <div className="mt-2">
              <span
                className={`inline-block px-2 py-1 rounded-md ${
                  task.status === 'Completed'
                    ? 'bg-green-500 text-white'
                    : 'bg-yellow-500 text-black'
                }`}
              >
                {task.status}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskManagement;
