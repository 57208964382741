import React from 'react';

const ProjectDetails = () => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-4">
      {/* Cover Image */}
      <div className="mb-4">
        <img
          src="https://images.unsplash.com/photo-1689608910785-747671c5d24f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=80"
          alt="Project Cover"
          className="w-full h-40 md:h-48 object-cover rounded-lg"
        />
      </div>

      {/* Project Title */}
      <h2 className="text-2xl font-bold mb-4">Project Title</h2>

      {/* Project Details */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Left Column */}
        <div className="space-y-4">
          {/* Project Description */}
          <div>
            <h3 className="text-xl font-semibold">Description</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel
              arcu ut lacus consequat finibus. Nullam auctor, dolor nec auctor
              fermentum, justo metus ultrices sapien, sit amet malesuada nulla
              felis vel ipsum.
            </p>
          </div>

          {/* Project Skills */}
          <div>
            <h3 className="text-xl font-semibold">Skills Required</h3>
            <ul className="grid grid-cols-2 gap-2">
              <li className="px-2 py-1 rounded-md bg-blue-500 text-white">
                ReactJS
              </li>
              <li className="px-2 py-1 rounded-md bg-blue-500 text-white">
                Node.js
              </li>
              {/* Add more skills as needed */}
            </ul>
          </div>

          {/* Project Status */}
          <div>
            <h3 className="text-xl font-semibold">Status</h3>
            <p className="text-green-500 font-semibold">In Progress</p>
          </div>

          {/* GitHub Repo */}
          <div>
            <h3 className="text-xl font-semibold">GitHub Repository</h3>
            <a
              href="https://github.com/yourusername/project-repo"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              https://github.com/yourusername/project-repo
            </a>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-4">
          {/* Project Owner */}
          <div>
            <h3 className="text-xl font-semibold">Project Owner</h3>
            <div className="flex items-center space-x-4">
              <img
                src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80"
                alt="Project Owner"
                className="w-10 h-10 rounded-full"
              />
              <span>John Doe</span>
            </div>
          </div>

          {/* Project Team */}
          <div>
            <h3 className="text-xl font-semibold">Project Team</h3>
            <div className="flex space-x-2">
              <img
                src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                alt="Team Member 1"
                className="w-10 h-10 rounded-full"
              />
              <img
                src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                alt="Team Member 2"
                className="w-10 h-10 rounded-full"
              />
              {/* Add more team members as needed */}
            </div>
          </div>

          {/* Project Deadline */}
          <div>
            <h3 className="text-xl font-semibold">Deadline</h3>
            <p>Due by: 25th September 2023</p>
          </div>
        </div>
      </div>

      {/* Project Actions */}
      <div className="flex items-center justify-end mt-4 space-x-4">
        <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
          Edit Project
        </button>
        <button className="bg-red-500 text-white px-4 py-2 rounded-md">
          Delete Project
        </button>
      </div>
    </div>
  );
};

export default ProjectDetails;
