import React from 'react';

const Milestones = () => {
  const milestones = [
    {
      id: 1,
      title: 'Project Kickoff',
      date: 'July 1, 2023',
      description: 'Start of the project and team setup.',
    },
    {
      id: 2,
      title: 'Phase 1 Complete',
      date: 'August 15, 2023',
      description: 'Completion of the first phase of development.',
    },
    // Add more milestones as needed
  ];

  return (
    <div className="  rounded-lg p-6 mb-4 bg-gray-50">
      {/* Section Title */}
      <h2 className="text-2xl font-bold mb-4">Milestones</h2>

      {/* Milestone List */}
      <div className="space-y-4">
        {milestones.map((milestone) => (
          <div key={milestone.id} className="bg-white border border-gray-300 rounded-lg p-4">
            <h3 className="text-lg font-semibold">{milestone.title}</h3>
            <div className="flex items-center mt-2">
              <div className="flex items-center space-x-2">
                <svg
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <span className="text-gray-600">{milestone.date}</span>
              </div>
            </div>
            <p className="text-gray-600 mt-2">{milestone.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Milestones;
