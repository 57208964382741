import React from 'react';

const TeamDetails = () => {
  const teamMembers = [
    {
      id: 1,
      name: 'John Doe',
      role: 'Software Engineer',
      profileImage: 'https://via.placeholder.com/100',
      linkedInProfile: 'https://www.linkedin.com/in/johndoe/',
      twitterProfile: 'https://twitter.com/johndoe/',
    },
    {
        id: 2,
        name: 'John Doe',
        role: 'Software Engineer',
        profileImage: 'https://via.placeholder.com/100',
        linkedInProfile: 'https://www.linkedin.com/in/johndoe/',
        twitterProfile: 'https://twitter.com/johndoe/',
      },
    // Add more team members as needed
  ];

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-4">
      {/* Section Title */}
      <h2 className="text-2xl font-bold mb-4">Team Details</h2>

      {/* Team Members */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {teamMembers.map((member) => (
          <div key={member.id} className="bg-gray-100 rounded-lg p-4">
            <div className="flex items-center justify-center mb-4">
              <img
                src={member.profileImage}
                alt={member.name}
                className="w-20 h-20 rounded-full"
              />
            </div>
            <div className="text-center">
              <h3 className="text-xl font-semibold">{member.name}</h3>
              <p className="text-gray-600">{member.role}</p>
            </div>
            <div className="mt-4 flex justify-center space-x-4">
              <a
                href={member.linkedInProfile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                LinkedIn
              </a>
              <a
                href={member.twitterProfile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Twitter
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamDetails;
