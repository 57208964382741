/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import 'matchmedia-polyfill';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import AboutUsPage from './pages/AboutUs';
import BlogPage from './pages/Blog';
import DailyCodeChallengePage from './pages/CodeChallenge';
import FeatureRequestPage from './pages/FeatureRequest';
import FeedsPage from './pages/Feed';
import HackathonsPage from './pages/Hackathons';
import InternshipsPage from './pages/Internships';
import InternshipsDetails from './pages/InternshipDetails';
import CollegeNotesListing from './pages/CollegeNotes';
import ContactUs from './pages/ContactUs';
import EbookDetails from './minipages/EBookDetails';
import EbookListingPage from './pages/EBooks';
import MockInterviewsPage from './pages/MockInterviews';
import LiveContestPage from './pages/LiveContests';
import LiveSessionPage from './pages/LiveSessions';
import NewsPage from './pages/News';
import PollsPage from './pages/Polls';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import ProjectDetails from './minipages/ProjectDetails';
import UpcomingEvents from './pages/UpcomingEvents';
import ProjectIdeaGenerator from './AI/ProjectIdeaGenerator';
import BootcampPage from './Bootcamp/Bootcamps';
import ComingSoonPage from './pages/ComingSoon';
import NotFoundPage from './minipages/NotFound';
import FullStackBootcampPage from './Bootcamp/FullStack';
import AssignmentHelpPage from './AI/AssignmentHelp';
import ChatBot from './AI/ChatBot';
import AiLearningPlanGenerator from './AI/LearningPlanGenerator';
import AIBootcampPage from './Bootcamp/AI';
import DockerBootcampPage from './Bootcamp/Docker';
import PostmanBootcampPage from './Bootcamp/PostmanAPI';
import GateCoursePage from './Externals/GATE';
import ProjectManagement from './components/ProjectManagementDashboard/ProjectManagement';
import TechnicalDocs from './components/CollegeNotes/TechnicalDocs';
import ProfilePage from './pages/DigitalProfile';

function App() {
  return (
    <div className="App">
      <Router>
       <Routes>
         {/* Define your other routes here if needed */}
         {/* <Route path="/" element={<Home />} /> */}
         <Route path="/" element={<Dashboard />} />
         <Route path="/project-management-dashboard" element={<ProjectManagement />} />

         {/* <Route path="/about-us" element={<AboutUsPage/>} />
         <Route path="/blog" element={<BlogPage />} />
         <Route path="/code-challenge" element={<DailyCodeChallengePage />} />
         <Route path="/feature-request" element={<FeatureRequestPage />} />
         <Route path="/feed" element={<FeedsPage />} />
         <Route path="/hackathons" element={<HackathonsPage />} />
         <Route path="/internship" element={<InternshipsPage />} />
         <Route path="/internship-details" element={<InternshipsDetails />} />
         <Route path="/college-notes" element={<CollegeNotesListing />} />
         <Route path="/contact-us" element={<ContactUs />} />
         <Route path="/ebook-details" element={<EbookDetails />} />
         <Route path="/ebooks" element={<EbookListingPage />} />
         <Route path="/mock-interviews" element={<MockInterviewsPage />} />
         <Route path="/live-contest" element={<LiveContestPage />} />
         <Route path="/live-sessions" element={<LiveSessionPage />} />
         <Route path="/news" element={<NewsPage />} />
         <Route path="/polls" element={<PollsPage/>} />
         <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
         <Route path="/project-details" element={<ProjectDetails />} />
         <Route path="/upcoming-events" element={<UpcomingEvents />} />
         <Route path="/project-management-dashboard" element={<ProjectManagement />} />
         <Route path="/college-notes/topic" element={<TechnicalDocs />} />
         <Route path="/username" element={<ProfilePage />} />
         <Route path="/coming-soon" element={<ComingSoonPage />} />

         <Route path="/bootcamps" element={<BootcampPage />} />
         <Route path="/bootcamps/full-stack-developer" element={<FullStackBootcampPage />} />
         <Route path="/bootcamps/ai" element={<AIBootcampPage />} />
         <Route path="/bootcamps/docker" element={<DockerBootcampPage />} />
         <Route path="/bootcamps/postman" element={<PostmanBootcampPage/>} />

         <Route path="/ai-tools/project-ideas" element={<ProjectIdeaGenerator />} />
         <Route path="/ai-tools/assignment-help" element={<AssignmentHelpPage />} />
         <Route path="/ai-tools/chatbot" element={<ChatBot />} />
         <Route path="/ai-tools/learning-plan" element={<AiLearningPlanGenerator />} />

         <Route path="/gate" element={<GateCoursePage />} />
 */}



         <Route path="*" element={<NotFoundPage />} />

      </Routes>
    </Router>
    </div>
  );
}

export default App;
