import React, { useState } from 'react';

const initialNewComment = {
  user: {
    name: 'Your Name',
    avatar: 'https://via.placeholder.com/150',
  },
  rating: 0,
  date: new Date().toLocaleDateString(),
  comment: '',
};

const ReviewSection = () => {
  const [newComment, setNewComment] = useState(initialNewComment);
  const [reviews, setReviews] = useState([
    {
      id: 1,
      user: {
        name: 'John Doe',
        avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      },
      rating: 5,
      date: 'July 15, 2023',
      comment:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vestibulum odio at lectus cursus.'
    },
    {
      id: 2,
      user: {
        name: 'Jane Smith',
        avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      },
      rating: 4,
      date: 'July 10, 2023',
      comment:
        'Suspendisse potenti. Fusce efficitur bibendum velit, quis tristique nunc gravida eu.'
    },
    // Add more reviews as needed
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewComment({
      ...newComment,
      [name]: value,
    });
  };

  const addNewComment = () => {
    if (newComment.comment.trim() === '') {
      return;
    }
    setReviews((prevReviews) => [
      ...prevReviews,
      {
        id: prevReviews.length + 1,
        user: { ...newComment.user },
        rating: parseInt(newComment.rating),
        date: newComment.date,
        comment: newComment.comment,
      },
    ]);
    setNewComment(initialNewComment);
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold mb-4">Customer Reviews</h2>
      <div className="space-y-4">
        {reviews.map((review) => (
          <div key={review.id} className="flex space-x-4">
            <img
              src={review.user.avatar}
              alt={`${review.user.name}'s avatar`}
              className="w-12 h-12 rounded-full"
            />
            <div className="flex-1">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold">{review.user.name}</h3>
                <div className="ml-2">
                  {Array.from({ length: review.rating }, (_, index) => (
                    <span
                      key={index}
                      className="text-yellow-500 text-xl"
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
              <p className="text-gray-600 text-sm">{review.date}</p>
              <p className="mt-2">{review.comment}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-2">Add Your Review</h3>
        <div className="flex space-x-4">
          <img
            src={newComment.user.avatar}
            alt={`${newComment.user.name}'s avatar`}
            className="w-12 h-12 rounded-full"
          />
          <div className="flex-1">
            <div className="mb-2">
              <input
                type="text"
                name="comment"
                value={newComment.comment}
                onChange={handleInputChange}
                placeholder="Write your review here..."
                className="w-full border rounded-lg p-2"
              />
            </div>
            <div className="flex items-center space-x-2">
              <span>Your Rating:</span>
              {Array.from({ length: 5 }, (_, index) => (
                <span
                  key={index}
                  onClick={() => setNewComment({ ...newComment, rating: index + 1 })}
                  className={`cursor-pointer text-yellow-500 text-xl ${
                    index < newComment.rating ? 'opacity-100' : 'opacity-50'
                  }`}
                >
                  ★
                </span>
              ))}
            </div>
            <button
              onClick={addNewComment}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg">
              Submit Review
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;
